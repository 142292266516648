@import url(https://fonts.googleapis.com/css2?family=Bree+Serif&family=Fira+Sans:wght@300;700&display=swap);
html, body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: #1A1A1D;
}

.joinInnerContainer {
  width: 20%;
}

.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
}

.heading {
  color: white;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

.button {
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #2979FF;
    padding: 20px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
  }


}

button:focus {
  outline: 0;
}
.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1A1A1D;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 8px;
  height: 60%;
  width: 35%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .outerContainer {
    height: 100%;
  }

  .container {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 480px) and (max-width: 1200px) {
  .container {
    width: 60%;
  }
}
.infoBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2979FF;
  border-radius: 4px 4px 0 0;
  height: 60px;
  width: 100%;
}

.leftInnerContainer {
  flex: 0.5 1;
  display: flex;
  align-items: center;
  margin-left: 5%;
  color: white;
}

.rightInnerContainer {
  display: flex;
  flex: 0.5 1;
  justify-content: flex-end;
  margin-right: 5%;
}

.onlineIcon {
  margin-right: 5%;
}
.form {
  display: flex;
  border-top: 2px solid #D3D3D3;
}

.input {
  border: none;
  border-radius: 0;
  padding: 5%;
  width: 80%;
  font-size: 1.2em;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.sendButton {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #2979FF;
  padding: 20px;
  display: inline-block;
  border: none;
  width: 20%;
}
.messageBox {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: 'Bree Serif', serif;
  color: #828282;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #2979FF;
}

.backgroundLight {
  background: #F3F3F3;
}
.messages {
  padding: 5% 0;
  overflow: auto;
  flex: auto;
}
.textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  color: white;
  height: 60%;
  justify-content: space-between;
}

.activeContainer {
  display: flex;
  align-items: center;
  margin-bottom: 50%;
}

.activeItem {
  display: flex;
  align-items: center;
}

.activeContainer img {
  padding-left: 10px;
}

.textContainer h1 {
  margin-bottom: 0px;
}

@media (min-width: 320px) and (max-width: 1200px) {
  .textContainer {
    display: none;
  }
}
* {
  font-family: "Fira Sans", sans-serif;
}
